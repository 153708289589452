import { Entry } from "data-handler"
import type { EntryRawData } from "data-handler/src/types"
import type { ChargebeeCustomer, ChargebeeSubscription } from "luxedo-rpc/src/routes/chargebee"

// The Chargbee types found in the luxedo-rpc chargebee route do not seem to align with the user data pulled
export type UserChargbeeSubscription = {
	addon?: {
		quantity: number
	}
	amount: number
	billing_period: number
	billing_period_unit: "year" | "month"
	end_date: number | null
	next_billing: number | null
	paid: boolean
	price_id: string
	per_unit_amount: number
	quantity: number
	status: "active" | "cancelled" | "non_renewing"
}

export type UserChargebeeCustomer = {
	id: string
	allow_direct_debit: boolean
	auto_collection: "on" | "off"
	card_status: string
	channel: "web"
	created_at: number
	updated_at: number
	deleted: boolean
	email: string
	excess_payments: number
	first_name: string
	last_name: string
	mrr: number
	net_term_days: number
	object: "customer"
	pii_cleared: string
	preferred_currency_code: string
	promotional_credits: number
	refundable_credits: number
	resource_version: number
	tax_provider_fields: []
	texability: string
	unbilled_charges: number
	payment_method: {
		gateway: string
		gateway_account_id: string
		object: "payment_method"
		reference_id: string
		status: string
		type: string
	}
}

/**
 * see luxedo/privileges/privileges.py for more details
 

	PUBLIC_MEDIA = PrivilegeType('public_media', [])
	TEST_STATION = PrivilegeType('test_station', [])
	FREE_SYNTH = PrivilegeType('free_synth', [])		# Gets an infinite number of synthetic projectors

	#### Roles ####
	# Line operator for test station
	OPERATOR = PrivilegeType("operator", [TEST_STATION])
	# Someone who designs graphics for our site
	GRAPHICS_DESIGNER = PrivilegeType("graphics", [PUBLIC_MEDIA])
	# Someone who works with us to produce content (Like Jeff Baird 106)
	CONTENT_PRODUCER = PrivilegeType('content_producer', [])
	# Users from before filesystem limitations who have extra space (users 67, 90, 93, 105, 117)
	LEGACY_USER = PrivilegeType('legacy_user', [])
	SCENE_20MIN = PrivilegeType('scene_20min', [])
	SCENE_30MIN = PrivilegeType('scene_30min', [])
	SCENE_60MIN = PrivilegeType('scene_60min', [])
	EDITOR_BETA = PrivilegeType('editor_beta', [])

	
 */

export type UserPriv =
	| "public_media"
	| "test_station"
	| "free_synth"
	| "operator"
	| "graphics"
	| "content_producer"
	| "legacy_user"
	| "scene_20min"
	| "scene_30min"
	| "scene_60min"
	| "editor_beta"
	| "admin"

export class User extends Entry<UserRawData> {
	//#region    =========================				   Initial Setup				==============================

	declare email: string
	declare firstname?: string
	declare lastname?: string
	declare privs?: Array<UserPriv>

	declare luxlink_max: number
	declare is_demo: boolean
	declare demo_remaining_time?: number
	declare tag_color_index: number

	declare directories: {
		projects: number
		media: number
		general: number
		lightshow: number
		public: Array<number>
	}

	declare storage: {
		base: number
		available: number
		free: number
	}

	declare chargebee: {
		customer?: UserChargebeeCustomer
		luxlinkPlan?: UserChargbeeSubscription
		storagePlan?: UserChargbeeSubscription
	}

	constructor(userData: UserRawData) {
		super(userData)
	}

	//#endregion =====================================================================================================

	//#region    =========================			     Main Functionality				==============================

	//#endregion =====================================================================================================

	//#region    =========================				   Implementation				==============================

	public exportData(): Partial<UserRawData> {
		return {
			email: this.email,
			firstname: this.firstname,
			lastname: this.lastname,
			tag_color_index: this.tag_color_index,
		}
	}

	protected importData(data: UserRawData): void {
		this.email = data.email
		this.firstname = data.firstname
		this.lastname = data.lastname
		this.privs = data.privs as Array<UserPriv>

		this.is_demo = data.luxlink_demo_active ?? false
		this.demo_remaining_time = data.luxlink_demo_ts
		this.tag_color_index = data.tag_color_index ?? 0
		this.luxlink_max = data.luxlink_max ?? 0

		this.storage = {
			base: data.storage_space_base ?? 0,
			available: data.storage_space_avail ?? 0,
			free: data.storage_space_free ?? 0,
		}

		this.directories = {
			projects: data.froot_projects,
			general: data.froot_general,
			media: data.froot_media,
			lightshow: data.froot_lightshows,
			public: data.public_folder_ids ?? [],
		}

		this.chargebee = {
			customer: data.customer,
			luxlinkPlan: data.phantasm_plan,
			storagePlan: data.storage_plan,
		}
	}

	//#endregion =====================================================================================================

	public hasPriv(priv: UserPriv) {
		if (this.privs.includes("admin")) return true
		return this.privs.includes(priv)
	}
}

export interface UserRawData extends EntryRawData {
	/* Actual Database Columns */
	username?: string
	email: string
	firstname?: string
	lastname?: string
	newuser: boolean
	luxlink_demo_ts?: number
	is_demo: boolean
	demo_valid_until?: string
	froot_projects: number
	froot_media: number
	froot_lightshows: number
	froot_general: number
	last_login?: string
	tag_color_index?: number

	/* Aux dict */
	public_folder_ids?: number[]
	shared_folder_ids?: number[]
	privs?: string[]
	prev_last_login?: Date
	storage_space_base?: number
	storage_space_avail?: number
	storage_space_free?: number
	customer?: UserChargebeeCustomer
	subscriptions?: {
		[index: string | number]: UserChargbeeSubscription
	}
	storage_plan?: UserChargbeeSubscription
	phantasm_plan?: UserChargbeeSubscription
	luxlink_max?: number
	luxlink_demo_active?: boolean
}
