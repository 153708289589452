import { DataHandlerDevice, Device, DeviceRPi } from "luxedo-data"
import { openUpdateInformerOverlay } from "../../comps/reusable/overlays/device-update"

export namespace DeviceUpdateManager {
	let deviceList: Array<number>

	// key: device ID, value: update listener ID
	let deviceUpdateIdMap: { [index: number]: string } = {}

	// key: device ID, value:
	let informedMap: { [index: number]: boolean } = {}

	/**
	 * Called when the device received an eidos update. If an update is available, open the update overlay.
	 */
	function onDeviceUpdate(device: Device) {
		if (!device.isOnline) return

		if (device.isUpdateAvailable()) {
			if (!informedMap[device.id])
				openUpdateInformerOverlay(device, (device as DeviceRPi).availableUpdate)
			informedMap[device.id] = true
		}

		const updateId = deviceUpdateIdMap[device.id]
		if (updateId) device.removeUpdateListener(updateId)
	}

	/**
	 * Converts a list of devices to a list of device IDs
	 */
	function convertDevicesToIdArray(devices: Array<Device>) {
		return devices.map((dev) => dev.id).sort((a, b) => a - b)
	}

	/**
	 * Clear all eidos listeners
	 */
	function clearListeners() {
		for (const [devId, updateId] of Object.entries(deviceUpdateIdMap)) {
			DataHandlerDevice.get(Number(devId))?.removeUpdateListener(updateId)
		}
	}

	/**
	 * Initializes update listeners
	 */
	export function initialize() {
		const allDevices = DataHandlerDevice.getMany()
		deviceList = convertDevicesToIdArray(allDevices)

		for (const device of allDevices) {
			let updateId = device.addUpdateListener(onDeviceUpdate)
			deviceUpdateIdMap[device.id] = updateId
		}
	}

	export async function triggerUpdate(device: Device) {
		await device.update()

		let interval = setInterval(async () => {
			await DataHandlerDevice.pull([device.id])
			const dev = DataHandlerDevice.get(device.id)
			if (!dev.isUpdateAvailable()) clearInterval(interval)
		}, 2500)
	}

	// If a device is added or removed, re-initialize the listeners above
	DataHandlerDevice.addListener(() => {
		if (!deviceList || deviceList.length === 0) return

		const userDevices = DataHandlerDevice.getMany()
		const newDeviceList = convertDevicesToIdArray(userDevices)
		if (!compareArraysAreEqual(newDeviceList, deviceList)) {
			clearListeners()
			initialize()
		}
	})
}

function compareArraysAreEqual(listA: Array<number>, listB: Array<number>) {
	return JSON.stringify(listA.sort()) === JSON.stringify(listB.sort())
}
