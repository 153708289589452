<script lang="ts">
	import { DataHandlerDevice, Device } from "luxedo-data"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { Toast } from "svelte-comps/toaster"
	import ProjectorAdvancedInputs from "./ProjectorAdvanced_Inputs.svelte"

	// #region =====================		Device State 		=====================

	export let triggerSave: () => Promise<void> = undefined

	let device: Device
	let isOnline: boolean = false
	let eidosListener: string

	SelectedDeviceStore.subscribe((dev) => {
		if (eidosListener) device?.removeUpdateListener(eidosListener)

		device = dev
		eidosListener = device?.addUpdateListener(onEidosUpdate)

		onEidosUpdate(dev)
	})

	function onEidosUpdate(dev: Device) {
		isOnline = dev?.isOnline ?? false
	}

	// #region =====================		Device State 		=====================

	/**
	 * Verifies the user wants to remove a device from their account
	 */
	function verifyRemove() {
		openConfirmOverlay({
			prompt: [
				"Are you sure you want to remove this device from your account?",
				"This cannot be undone.",
			],
			buttons: {
				confirm: {
					text: "Yes",
					onClick: removeDevice,
				},
			},
		})
	}

	/**
	 * Removes the selected device from the logged in user's account
	 */
	async function removeDevice() {
		try {
			await DataHandlerDevice.deleteEntry(device)
			const devices = DataHandlerDevice.getMany()
			if (devices.length) SelectedDeviceStore.set(devices[0])
			else SelectedDeviceStore.set(undefined)
			Toast.success("Device successfully removed from your account.")
		} catch (e) {
			console.error("Error removing device from user account: ", e)
			Toast.error(
				"An error occurred while trying to remove this device from your account. Please try again..."
			)
		}
	}
</script>

<div class="advanced-options">
	{#if isOnline}
		<ProjectorAdvancedInputs bind:triggerSave />
	{:else}
		<p class="disconnected">⚠ Power on device to access advanced settings.</p>
	{/if}

	<div class="button-container">
		<button
			id="remove-device-button"
			class="outline-button"
			title="Remove Device from Account"
			on:click={verifyRemove}
		>
			Remove from Account
		</button>
	</div>
</div>

<style>
	.advanced-options {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.disconnected {
		margin-bottom: 0;
		color: var(--color-main);
	}

	#remove-device-button {
		border-color: var(--color-error);
	}

	#remove-device-button:hover,
	#remove-device-button:focus-visible {
		background-color: var(--color-error);
	}

	.button-container {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1rem;
	}
</style>
